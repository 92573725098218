import { convertArrayToPropIndexedObject, type ConvertArrayToPropIndexedObject, type MutableOrImmutableArray } from "@mcwd/typescript-type-guards";
import type { ISiteLocale, ISiteLocaleKey } from "./ISiteLocale.js";


export type buildExtendedLocaleOnlySettingsObj<
  ARRAY extends MutableOrImmutableArray<any>,
  IndexByProp extends PropertyKey & keyof (ARRAY extends MutableOrImmutableArray<infer U>? U : any)
> = ConvertArrayToPropIndexedObject<ARRAY, IndexByProp>;

export function buildExtendedLocaleOnlySettingsObj<
  const TLocaleSettingsArray extends ISiteLocale[],
  const IndexByProp extends ISiteLocaleKey
>(localeSettingsArr: TLocaleSettingsArray, indexBy: IndexByProp): buildExtendedLocaleOnlySettingsObj<TLocaleSettingsArray, IndexByProp> {
  return convertArrayToPropIndexedObject(localeSettingsArr, indexBy);
}